import { navigate } from "gatsby"
import { Link } from "@StarberryUtils";
import React, { useState, useEffect, useRef, createRef } from "react"
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import SelectBox from "../SelectBox/SelectBox"
import BannerSearch from "./BannerSearch"

import { Helmet } from "react-helmet";
import _ from "lodash";
// Images
import BannerLarge from "@images/home/main-banner-large.webp"
import BannerMedium from "@images/home/main-banner-medium.webp"
import BannerSmall from "@images/home/main-banner-small.webp"
// styles
import { bounceInRight, bounceInLeft } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import $ from "jquery"
import { ImageModule, SourceBlock } from '../../../modules/Image_Module';
import "./Banner.scss"
import { SearchResults } from "semantic-ui-react";
import ReactPlayer from 'react-player'
import * as Icon from 'react-bootstrap-icons';
import ShowMoreText from 'react-show-more-text';

import SearchInputBox from '../../predictive-search/search-inputbox';

import PodcastButton from '../../../modules/podcast_button';
//import googleReview2 from "../../../images/home/google-reviews-2.svg"
import GoogleReview from "../../../images/reviews-logo-color.png"
import wordsToNumbers from 'words-to-numbers';
import HTMLReactParser from 'html-react-parser';

import { youtube_parser, priceminRangeSale, pricemaxRangeSale, priceminRangeRent, pricemaxRangeRent } from "../../common/utils";
import EnquireForm from '../../forms/banner_enquire_form';
import { useLocation } from "@reach/router";

const Banner = (props) => {
   console.log("props",props)
  const [search, setSearch] = useState(false);
  const [playing, setPlay] = useState(true);
  const [mute, setMute] = useState(true);
  const [vid_duration, setVidDuration] = useState(0);

  const [playing2, setPlay2] = useState(false);

  // Property status
  const [selectedPropertyTypeValue, setSelectedPropertyTypeValue] = useState('/properties/for-sale/');
  const propertyStatus = [
    { value: '/properties/for-sale/', label: 'For Sale' },
    { value: '/properties/sold/', label: 'Sold' },
    { value: '/properties/for-rent/', label: 'For Rent' },
    { value: '/properties/let/', label: 'Let' },
    { value: '/properties/commercial/for-sale/', label: 'Commercial Sale' },
    { value: '/properties/commercial/for-rent/', label: 'Commercial Rent' },
  ];
  // Property status

  // Min price
  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
  var minOptions = priceminRangeSale;



  // Max price
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
  var maxOptions = pricemaxRangeSale;
  // Max price


  // Resi sales Proeprty type
  const [selectedResiSalesValue, setSelectedResiSalesValue] = useState();
  const salesPropertyType = [
    { value: "", label: "All Types" },
    { value: "apartments", label: "Apartments" },
    { value: "townhouses", label: "Townhouses" },
    { value: "villas", label: "Villas" },
    { value: "penthouses", label: "Penthouses" },
    { value: "duplexes", label: "Duplexes" },
    { value: "lands", label: "Lands" },
    { value: "office-spaces", label: "Office Spaces" },
    { value: "chalets", label: "Chalets" },
  ];
  // Resi sales Proeprty type

  // Resi rentals Proeprty type
  const [selectedResiRentalsValue, setSelectedResiRentalsValue] = useState();
  const rentalsPropertyType = [
    { value: "", label: "All Types" },
    { value: "apartments", label: "Apartments" },
    { value: "townhouses", label: "Townhouses" },
    { value: "penthouses", label: "Penthouses" },
    { value: "duplexes", label: "Duplexes" },
    { value: "studio", label: "Studio" },
    { value: "villas", label: "Villas" },
    { value: "chalets", label: "Chalets" },
  ];
  // Resi rentals Proeprty type

  // Comm sales Proeprty type
  const [selectedCommSalesValue, setSelectedCommSalesValue] = useState();
  const commsalesPropertyType = [
    { value: "", label: "All Types" },
    { value: "office-spaces", label: "Office Spaces" },
  ];
  // Comm sales Proeprty type

  // Comm rentals Proeprty type
  const [selectedCommRentalsValue, setSelectedCommRentalsValue] = useState();
  const commrentalsPropertyType = [
    { value: "", label: "All Types" },
    { value: "office-spaces", label: "Office Spaces" },
  ];
  // Comm rentals Proeprty type

  // Min bedrooms
  const [selectedMinBedroomsValue, setSelectedMinBedroomsValue] = useState("");
  const MinBed = [
    { value: "", label: "Bedrooms" },
    { value: "0", label: "Studio" },
    { value: "1", label: "1 +" },
    { value: "2", label: "2 +" },
    { value: "3", label: "3 +" },
    { value: "4", label: "4 +" },
    { value: "5", label: "5 +" },
    // { value: "6", label: "6 +" },
    // { value: "7", label: "7 +" },
    // { value: "8", label: "8 +" },
    // { value: "9", label: "9 +" },
    // { value: "10", label: "10 +" },
    // { value: "11", label: "11 +" },
    // { value: "12", label: "12 +" },
    // { value: "13", label: "13 +" },
    // { value: "14", label: "14 +" },
    // { value: "15", label: "15 +" },
    // { value: "16", label: "16 +" },
    // { value: "17", label: "17 +" },
    // { value: "18", label: "18 +" },
    // { value: "19", label: "19 +" },
    // { value: "20", label: "20 +" }
  ];
  // Min bedrooms

  // Max bedrooms
  const [selectedMaxBedroomsValue, setSelectedMaxBedroomsValue] = useState(0);
  const MaxBed = [
    { value: "0", label: "Max Bedrooms" },
    { value: "1", label: "1 +" },
    { value: "2", label: "2 +" },
    { value: "3", label: "3 +" },
    { value: "4", label: "4 +" },
    { value: "5", label: "5 +" },
    // { value: "6", label: "6 +" },
    // { value: "7", label: "7 +" },
    // { value: "8", label: "8 +" },
    // { value: "9", label: "9 +" },
    // { value: "10", label: "10 +" },
    // { value: "11", label: "11 +" },
    // { value: "12", label: "12 +" },
    // { value: "13", label: "13 +" },
    // { value: "14", label: "14 +" },
    // { value: "15", label: "15 +" },
    // { value: "16", label: "16 +" },
    // { value: "17", label: "17 +" },
    // { value: "18", label: "18 +" },
    // { value: "19", label: "19 +" },
    // { value: "20", label: "20 +" }
  ];
  // Max bedrooms

  // Property status dropdown
  const onChangeSellRent = (e) => {
    setSelectedPropertyTypeValue(e.value);
    if (selectedPropertyTypeValue === "/properties/for-sale/") {
      setSelectedResiRentalsValue("");
      setSelectedCommSalesValue("");
      setSelectedCommRentalsValue("");
    } else if (selectedPropertyTypeValue === "/properties/for-rent/") {
      setSelectedResiSalesValue("");
      setSelectedCommSalesValue("");
      setSelectedCommRentalsValue("");
    } else if (selectedPropertyTypeValue === "/properties/commercial/for-sale/") {
      setSelectedResiSalesValue("");
      setSelectedResiRentalsValue("");
      setSelectedCommRentalsValue("");
    } else if (selectedPropertyTypeValue === "/properties/commercial/for-rent/") {
      setSelectedResiSalesValue("");
      setSelectedResiRentalsValue("");
      setSelectedCommSalesValue("");
    }
    // else if (selectedPropertyTypeValue === "/properties/sold/") {
    //   setSelectedResiSalesValue("");
    //   setSelectedResiRentalsValue("");
    //   setSelectedCommSalesValue("");
    // } else if (selectedPropertyTypeValue === "/properties/let/") {
    //   setSelectedResiSalesValue("");
    //   setSelectedResiRentalsValue("");
    //   setSelectedCommSalesValue("");
    // }
  }
  // Property status dropdown

  // Min price
  const onChangeMinPrice = (e) => {
    setSelectedMinPriceValue(e.value);
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = (e) => {
    setSelectedMaxPriceValue(e.value);
  }
  // Max Price

  // Resi Sales Property type
  const onChangeResiSalesPropertyType = (e) => {
    setSelectedResiSalesValue(e.value);
  }
  // Resi Sales Property type

  // Resi Rentals Property type
  const onChangeResiRentalsPropertyType = (e) => {
    setSelectedResiRentalsValue(e.value);
  }
  // Resi Rentals Property type

  // Comm Sales Property type
  const onChangeCommSalesPropertyType = (e) => {
    setSelectedCommSalesValue(e.value);
  }
  // Comm Sales Property type

  // Comm Rentals Property type
  const onChangeCommRentalsPropertyType = (e) => {
    setSelectedCommRentalsValue(e.value);
  }
  // Comm Rentals Property type

  // Min Bedrooms
  const onChangeMinBedrooms = (e) => {
    setSelectedMinBedroomsValue(e.value);
  }
  // Min Bedrooms

  // Max Bedrooms
  const onChangeMaxBedrooms = (e) => {
    setSelectedMaxBedroomsValue(e.value);
  }
  // Max Bedrooms

  // Search results banner
  let url = "/";
  let priceUrl = ""
  let bedroomsUrl = ''

  const searchResultsBanner = (e) => {
    var searchValue = $(".search-filter-banner .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();

    if (searchValue !== "") {
      url = "in-" + searchValue + "/"
    } else {
      url = "in-doha-qatar/"
    }

    if ((selectedMinPriceValue !== undefined) && (selectedMaxPriceValue !== undefined) && (selectedMinPriceValue > 0) && (selectedMaxPriceValue > 0)) {
      priceUrl = "between-" + selectedMinPriceValue + "-and-" + selectedMaxPriceValue
    } else if ((selectedMinPriceValue !== undefined) && (selectedMinPriceValue > 0)) {
      priceUrl = "over-" + selectedMinPriceValue
    } else if ((selectedMaxPriceValue !== undefined) && (selectedMaxPriceValue > 0)) {
      priceUrl = "under-" + selectedMaxPriceValue
    } else { priceUrl = "" }

    if ((selectedMinBedroomsValue !== undefined) && (selectedMaxBedroomsValue !== undefined) && (selectedMinBedroomsValue > 0) && (selectedMaxBedroomsValue > 0)) {
      bedroomsUrl = selectedMinBedroomsValue + "-to-" + selectedMaxBedroomsValue + "-bedrooms"
    } else if ((selectedMinBedroomsValue !== undefined) && (selectedMinBedroomsValue === "0")) {
      console.log("bedroomsUrl 1", selectedMinBedroomsValue);
      bedroomsUrl = "studio"
    } else if ((selectedMinBedroomsValue !== undefined) && (selectedMinBedroomsValue > 0)) {
      bedroomsUrl = selectedMinBedroomsValue + "-and-more-bedrooms"
    } else if ((selectedMaxBedroomsValue !== undefined) && (selectedMaxBedroomsValue > 0)) {
      bedroomsUrl = "upto-" + selectedMaxBedroomsValue + "-bedrooms"
    } else {
      console.log("bedroomsUrl 2", selectedMinBedroomsValue);
      bedroomsUrl = ""
    }

    var searchFields = (
      (selectedPropertyTypeValue ? selectedPropertyTypeValue : '/properties/for-sale/') + "" + url + "" +
      (selectedResiSalesValue ? "type-" + selectedResiSalesValue + "/" : '') + "" +
      (selectedResiRentalsValue ? "type-" + selectedResiRentalsValue + "/" : '') + "" +
      (selectedCommSalesValue ? "type-" + selectedCommSalesValue + "/" : '') + "" +
      (selectedCommRentalsValue ? "type-" + selectedCommRentalsValue + "/" : '') + "" +
      (bedroomsUrl ? bedroomsUrl + "/" : '') + "" +
      (priceUrl ? priceUrl + "/" : '')
    );

    navigate(searchFields);

    // alert(searchFields);
  }
  // Serach results banner

  const displaySearch = () => {
    // setSearch(!search)
    $(".search-filter-form").toggleClass("active");
    $(".find-more").toggleClass("decr_zindex");
    $(".main-banner").toggleClass("decr_zindex");

    setSearch(search)
  }

  // const outSideLink = (e) => {
  //   //console.log("className",e.target.classList, e.target.className);

  //   if(e.target.classList && e.target.classList.length > 0){
  //     if (!e.target.className.includes("filter-icon") && !e.target.className.includes('form-group')
  //     && !e.target.className.includes('css-2b097c-container') && !e.target.className.includes('select-opt__value-container')
  //     && !e.target.className.includes('search-filter') && !e.target.className.includes('form-label')
  //     && !e.target.className.includes('select-opt__placeholder') && !e.target.className.includes('select-opt__option')) {

  //       setSearch(search)
  //     }
  //   }

  // }



  useEffect(() => {
    //document.addEventListener('click', outSideLink);

  }, [])

  // console.log("CTA_1_URL", props.CTA_1_URL)

  var banner_cls = props.Layout === "Content_Full_Width" || props.Layout === "Rightside_Block" ? 'inner_banner' : ''; 

  // 
  var minPrice = [];
  var maxPrice = [];


  // Min price
  if ((selectedPropertyTypeValue === "/properties/for-sale/") || (selectedPropertyTypeValue === "/properties/commercial/for-sale/")) {
    minOptions = priceminRangeSale;
  } else {
    minOptions = priceminRangeRent;
  }

  // Max price
  if ((selectedPropertyTypeValue === "/properties/for-sale/") || (selectedPropertyTypeValue === "/properties/commercial/for-sale/")) {
    maxOptions = pricemaxRangeSale;
  } else {
    maxOptions = pricemaxRangeRent;
  }


  minOptions.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));
  maxOptions.map(items => parseInt(items.value) > parseInt(selectedMinPriceValue) && maxPrice.push(items));

  var minBed = [];
  var maxBed = [];

  MinBed.map(items => parseInt(items.value) < parseInt(selectedMaxBedroomsValue) && minBed.push(items));
  MaxBed.map(items => parseInt(items.value) > parseInt(selectedMinBedroomsValue) && maxBed.push(items));
  // 
  var youtube_url = props.Banner_Video && youtube_parser(props.Banner_Video);
  var video_url = "";
  if (youtube_url) {
    video_url = "https://www.youtube-nocookie.com/embed/" + youtube_url
  } else {
    video_url = props.Banner_Video
  }

  // var testimonial_count = props.testimonials ? props.testimonials.length : 0;
  // console.log("testimonial_count",testimonial_count)
  // var rating_count = 0;
  // props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
  //     rating_count += parseInt(wordsToNumbers(item.starRating));
  // })
  // var rating_avg = rating_count/testimonial_count;


  // var banner_content = props.Content && props.Alias === "real-estate-private-equity" ? HTMLReactParser(props.Content) : <ShowMoreText
  // lines={3}
  // more=''
  // less=''
  // className=''
  // anchorClass=''
  // expanded={false}
  // width={0}>
  //   {HTMLReactParser(props.Content)}
  // </ShowMoreText>;

  var banner_content = props.Content && HTMLReactParser(props.Content);
  var location = useLocation()
  //console.log("location",location)
  const [isPlay, setPlayVid] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const videoRef = createRef(null);
  const [render, setRender] = useState(false);
  const playVideo = () => {
    setPlayVid(true);
    videoRef.current.play();
  }
  const closeVideo = () => {
    setPlayVid(false);
    videoRef.current.pause();
  }

  const scrollToPos = (div_id) => {
    $('html, body').animate({
      scrollTop: $("#" + div_id).offset().top - 100
    }, 'smooth');
  }

  const showPlayButton = () => {
    //console.log("content_over");
    $(".banner-btn").css('display', 'block')
  }
  useEffect(() => {
    if (!render) {
      setRender(true)

    }
  })


  //console.log("banner", props)
  var StepContent = _.find(props.GQLModules, function (o) { return o.__typename === "GLSTRAPI_ComponentComStepContentBlock"; });
  // console.log("StepContent",StepContent)
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": `${props?.Banner_Title}`,
    "description": `${props?.description}`,
    "image": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png",
    "totalTime": "PT2M"
  };


  if (!_.isEmpty(StepContent)) {
    var step = [];
    if (!_.isEmpty(StepContent.Steps)) {
      _.forEach(StepContent.Steps, (val, key) => {
        step.push(
          {
            "@type": "HowToStep",
            "text": (val?.Content?.replace(/<[^>]+>/g, '')),
            "image": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png",
            "name": val.Title,
            "url": props?.location?.href + "#" + val?.Slug

          }
        )
      })
    }

    ldJson['step'] = step
  }

  return (
    <React.Fragment>
      {props.template === "common" && render && props.Layout === "Rightside_Block" && props.Alias.includes("-guide") &&
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>}
      { props.Alias === "snagging-and-handover-inspections" && props.template === "common" && props.location.origin &&
      <Helmet>
             <script type="application/ld+json">{`{
             
                "@context": "http://schema.org/",
                "@type": "Service",
                "brand": "${props?.location?.origin}",
                "serviceOutput": "${props?.Banner_Title}",
                "serviceType": "${props?.Banner_Title}",
                "description": "${props?.GQLPage?.Meta_Description}",
                "image": {
                    "@type": "ImageObject",
                    "caption": "${props?.Banner_Title}",
                    "contentUrl": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
                },
                "mainEntityOfPage": "${props?.location?.href}",
                 "name": " ${props?.Banner_Title} - The Pearl Gates"
            }

}`}</script>
      </Helmet>}
      <div className={`main-banner ${banner_cls} ${props.Banner_Video? "community-video": ""}`}>

        {
            <>
              <div className="main-banner-embed">
                {
                  props.Banner_Video ?
                  <>
                    <div className={`banner-video`} >
                      {
                        video_url.includes("www.youtube-nocookie.com") ? <>

                          {
                            (props.Layout === "Content_Full_Width" || props.Layout === "Rightside_Block") && (
                              playing && (
                                <a href="javascript:;" className="close_video" onClick={() => setPlay(false)}>X</a>
                              ))
                          }
                          <ReactPlayer
                            loop={true}
                            url={video_url}
                            playing={playing}
                            controls={false}
                            light={playing ? false : props.Banner_Image.url}
                            //light={playing ? false : true}
                            autoplay={true}
                            ref={videoRef}
                            muted={mute}
                            frameborder="0"
                            allowfullscreen
                            //onPause={() => setPlay(false)}
                            //onPlay={() => setPlay(true)}
                            //onStart={() => setTimeout(function(){ setMute(false);},1000)}
                            playIcon={<Icon.PlayCircle color="#fff" fontSize="50px" onClick={() => setPlay(true)} />}
                            className={`react_player`}
                          />

                        </> : <>
                          <div className="react_player mp4_video">

                            <video width="100%" height="100%" autoPlay muted={!isPlay} playsInline loop className="video-tag" ref={videoRef} controls={isPlay}>
                              <source src={`${video_url}`} type="video/mp4" />
                            </video>
                          </div>

                          {
                            props.Play_Button && <>
                            {/* {(video_url && !isPlay) &&
                              <div className="banner-btn">
                                  {showPause && <strong 
                                      className="video-btn" 
                                      onClick={playVideo}
                                  >
                                      <i className="icon-video" />
                                  </strong> }
                              </div>
                            }
                            {(video_url && isPlay) &&
                              <div className="banner-btn pause-btn" >
                                  {showPause && <strong 
                                      className="video-btn" 
                                      onClick={closeVideo}
                                  >
                                      <i className="icon-pause" />
                                  </strong> }
                              </div>
                            }  */}
                              {(video_url && !isPlay) &&
                                <div className="banner-btn">
                                  <strong className="video-btn" onClick={playVideo}>
                                    <i className="icon-video" />
                                  </strong>
                                </div>
                              }
                              {(video_url && isPlay) &&
                                <div className="banner-btn pause-btn" >
                                  <strong className="video-btn" onClick={closeVideo}>
                                    <i className="icon-pause" />
                                  </strong>
                                </div>
                              }
                            </>
                          }


                        </>
                      }


                    </div>
                  </> :
                  props.Banner_Image &&
                  <picture>
                    <ImageModule classNames={"bg-img"} ImageSrc={props.Banner_Image} imagename={props.imagename} article_id={props.article_id} imagetransforms={props.imagetransforms ? props.imagetransforms : props.ggfx_results} ggfxNew={true} />
                  </picture>

                }
                <span class="overlay_bg"></span>
              </div>

              {
                props.Layout === "Content_Full_Width" || props.Layout === "Rightside_Block" ? (
                  <div className={`main-banner-content ${props.Banner_Video ? "ban_video_text" : ""} ${props.Alias === 'contact-us' ? 'contact_banner' : ''}`} onMouseEnter={showPlayButton}>
                    <Container>
                      <div className="banner-text">
                        {
                          props.Show_Enquire_Form ? (
                            <Row>
                              <Col className="col-lg-8 col-md-12">
                                <h1>{props.Banner_Title}</h1>
                                <p className="desc">
                                  {/* {
                                    banner_content && banner_content.length > 180 ?  
                                      banner_content.substring(0, 180)+"..."
                                    : 
                                      banner_content
                                  } */}
                                  {banner_content}
                                  
                                </p>

                                { props.GQLModules && props.GQLModules.length > 0 && props.GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModAudioModule").map((Modules, i) => {
                                    return(
                                      <PodcastButton Modules={Modules} />
                                    )
                                })} 

                                <a href="javascript:;" className="btn btn-primary enquire_btn d-md-inline-block  d-lg-none" onClick={(e) => props.openModalForm(e,props.Form_Heading, 'multifamily_enquiry_form')}><span>Enquire Now</span></a> 
                              </Col>

                              <Col className="col-lg-4 d-none d-lg-inline">
                                <div className="bann_frm" id="enquiry_form">
                                  <h5>{props.Form_Heading}</h5>

                                  <EnquireForm />

                                </div>                                      
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <h1>{props.Banner_Title}</h1>
                              <p className="desc">{props.Content && HTMLReactParser(props.Content)} </p>

                              {
                                props.Show_Banner_Google_Review && (
                                  <div className="review-info">
                                      <div className="d-md-flex text-left justify-content-left align-items-center">
                                          <Link className="google-logo" to="#"><img src={GoogleReview} alt={`The Pearl Gates - Google`}/></Link>
                                          <div className="review-detail d-none d-md-block">
                                              <h6 className="total-review">{props?.ratingAvg ? parseFloat(props?.ratingAvg).toFixed(1) : 0}/5 Rating</h6>
                                              <span className="total-desc">from {parseInt(props?.totalReviewsCount)} reviews</span>
                                          </div>
                                      </div>
                                  </div>
                                )
                              }
                              
                              {props.CTA_1_Label ? <> 
                                {
                                  props.CTA_1_URL === "get_free_quote" ?
                                  <a href="javascript:;" className="btn btn-primary" onClick={(e) => props.openModalForm(e,props.CTA_1_Label, props.CTA_1_URL)}><span>{props.CTA_1_Label}</span></a> 
                                :
                                  props.CTA_1_URL === "open_positions" ?
                                  <a href="javascript:;" className="btn btn-primary" onClick={(e)=>scrollToPos(props.CTA_1_URL)}><span>{props.CTA_1_Label}</span></a> 
                                :
                                  props.CTA_1_URL === "snagging_form" ? 
                                  <a href="javascript:;" className="btn btn-primary" onClick={(e) => props.openModalForm(e,'Snagging & Handover - '+props.CTA_1_Label, props.CTA_1_URL)}><span>{props.CTA_1_Label}</span></a> 
                                : 
                                  <Link to={props.CTA_1_URL ? props.CTA_1_URL : "#"} state={{ prevPath: props.location.pathname }} className="btn btn-primary"><span>{props.CTA_1_Label}</span></Link>
                                }
                                </> : ''
                              }

                              {props.CTA_2_Label ? <> 
                                {props.CTA_2_URL === "send_cv" ? 
                                  <a href="javascript:;" className="btn btn-primary" onClick={props.openModalForm}><span>{props.CTA_2_Label}</span></a> 
                                : 
                                  <Link to={props.CTA_2_URL ? props.CTA_2_URL : "#"} state={{ prevPath: props.location.pathname }} className="btn btn-primary "><span>{props.CTA_2_Label}</span></Link>
                                }
                                </> : ''
                              }

                              { props.GQLModules && props.GQLModules.length > 0 && props.GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModAudioModule").map((Modules, i) => {
                                  return(
                                    <PodcastButton Modules={Modules} />
                                  )
                              })}  
                            </>
                          )
                        }
                      </div>
                    </Container>
                  </div>
                ) : (
                  <div className="main-banner-content text-center" onMouseEnter={showPlayButton}>
                    <Container>
                      <Row>
                        <Col lg={12}>
                      <div className="banner-text">
                        <h1>{props.Banner_Title}</h1>
                        {(props.CTA_1_Label || props.CTA_2_Label) ?
                        <ul className="cta-points">
                          {props.CTA_1_Label && <li><i className="cta1-icon"></i>{props.CTA_1_Label}</li>}
                          {props.CTA_2_Label && <li><i className="cta2-icon"></i>{props.CTA_2_Label}</li>}
                        </ul>
                        :""
                        }
                        {props.Content && <p className="desc">
                          {/* {HTMLReactParser(props.Content)} */}
                          {banner_content}
                        </p>}

                        <BannerSearch SearchText={props?.SearchText?props?.SearchText:''}/>
                        {/* {props.Alias === "sell"  ?
                        <div className="free-property-valuation">
                            <a class="btn btn-primary d-block d-md-inline-block " href="/how-much-is-my-home-worth/"><span>Free Property Valuations</span></a>
                        </div> : ""}    */}
                      </div>
                      </Col>
                      </Row>
                    </Container>
                  </div>
                )
              }
            </>

        }
      </div>

      {
        (props.Banner_Image && props?.templateclass !=="community-banner") &&
        <div className="find-more d-none">
          <a href="javascript:;" onClick={props.handleClick} className="findout-more">Find out more</a>
        </div>
      }
    </React.Fragment>
  )
}

export default Banner