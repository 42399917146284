import React,{useEffect, useState, createRef} from "react"
import { Helmet } from "react-helmet";
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLParser from 'html-react-parser';
import $ from 'jquery';
import axios from "axios"
import _ from "lodash"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Home/Banner/BannerCommunity.js";
import ContactInfo from "../components/Home/ContactInfo/ContactInfoCommunities";
import CommunitySearchList from "../components/Community/CommunitySearchList";
import TilesBlock from "../components/Home/ContactInfo/TileBlock";
import ProjectRating from "../components/Home/ProjectRating/ProjectRating_Communities"
import NewProjectRating from "../components/Home/ProjectRating/ProjectRating_NewProjects"
import Reviews from "../components/Home/Reviews/Reviews"
import GoogleSingleMap from "../components/maps/google/community_map"
import CountSection from "../components/CountSection/CountSection"
//import LatestBlogs from "../components/Home/LatestBlogs/LatestBlogscommunity"
import MemberCard from "../components/MemberCard/MemberCard"
import Videos from "../components/Videos/VideosCommunity"
import Modal from "react-bootstrap/Modal"
import LatestBlogsbyCategory from "../components/StaticOne/FilterBlock/LatestBlogsbyCategory"

import SpeakAdvisorForm from "../components/forms/community-form"

import '../scss/custom.scss';
import '../scss/community.scss';
import LoaderLogo from '../images/loader_logo_new.svg';

import {CommunityDetails, CommunityDistrict} from "../queries/common_use_query"
import { removeDataAttributes } from "../comQueryStructure.js";
import { graphql } from "gatsby";
import { google_review_url } from "../components/common/utils.js";
import Chart from "../components/Chart/chart.js";

function Community_Details(props){

  const data = removeDataAttributes(props?.data).glstrapi
  
    const district_data = data?.communityDistricts

    const find_more_ref = createRef();

    let path_name = (props.location.pathname).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];


    //const [details, setDetails] = useState(data.communities[0] || district_data[0]);
    const [details, setDetails] = useState("");

    //console.log("setDetails", details)

    const [isOpen, setIsOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [modalType, setModalType] = useState('');

    const categories = [
      {
        category_name:"Blogs"
      }
    ]
  

    // const {loading:loading, error:error, data:data} = CommunityDetails(get_url);
    // const {loading:district_loading, error:district_error, data:district_data} = CommunityDistrict(get_url);


    const [testimonials, setTestimonials] = useState([]);

    const findoutMore = () => {
        find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const getitems = async url => {
        try {
        const { data } = await axios.get(url,strapiconfig)// could be from env files
        const reviewsData = removeDataAttributes(data)
        setTestimonials(reviewsData)
        } catch (error) {
        // cache it if fail/error;
        console.error(error)
        }
    }
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }
    useEffect(() => {
        let url = google_review_url
        getitems(url);
    }, []);

    useEffect(() => {
        data && !_.isEmpty(data?.communities) && setDetails(data.communities[0]);
    },[data]);

    useEffect(() => {
        district_data && !_.isEmpty(district_data) && setDetails(district_data[0]);
    },[district_data]);

    var LayoutName = details && details.__typename === "GLSTRAPI_CommunityDistrict" ? 'Buy_Landing_Page comm_dist_det' : 'Buy_Landing_Page';
    LayoutName = LayoutName + ' community-detail-page'

    var location = details.Location ? ', '+details.Location : '';

    let processedImages = JSON.stringify({}), image_name='';
    if (details && details.imagetransforms && details.imagetransforms.Banner_Image_Transforms) {
        processedImages = details.imagetransforms.Banner_Image_Transforms;
    }

    image_name = details?.__typename==="GLSTRAPI_CommunityDistrict"?"community-district.Banner_Image.details":"community.Banner_Image.details"
    
  const openModalForm = (e, modal_heading, modal_type) => {
    e.preventDefault();

    setIsOpen(true);
    setModalHeading("Community page form");
    setModalType(modal_type);
  }

    useEffect(() => {
      $(document).ready(function () {
        $(".tab-switch ul li a").click(function (e) {
          e.preventDefault();
          var aid = $(this).attr("href");
          $('html, body').animate({
            scrollTop: $(aid).offset().top - ($(".header").outerHeight() + $(".tab-switch").outerHeight() + 50)
          }, 1000);
          $(this).parent().addClass('active').siblings().removeClass('active');


        });
  
        $(window).on('scroll', function () {
          if ($(".main-banner-embed")[0]?.getBoundingClientRect()?.bottom < 0) {
            $('.tab-switch').addClass('stick');
          } else {
            $('.tab-switch').removeClass('stick');
          }
        });
      });
    }, []);
    console.log(details)
    return(
    <Layout Layout={LayoutName} classNames={LayoutName} popular_search={details?.popular_search_communities?.length > 0 && details?.popular_search_communities[0]}>
         <SEO title={details.Meta_Title} description={details.Meta_Description} />

        <Helmet bodyAttributes={{class: `homepage`}}/>

        { details ? <>
            {details.Banner_Image && details.Banner_Image.url &&
                <Banner Banner_Video={details?.Banner_Video?.url?details?.Banner_Video?.url:null} Banner_Title={details.Name+location} CTA_1_Label={''}  CTA_2_Label={''} Banner_Image = {details.Banner_Image} Content = {details.Content} Layout={LayoutName} SearchText={details.Name}  imagename={image_name} article_id={details.id} imagetransforms={details.ggfx_results} handleClick={findoutMore} template={"community"} templateclass={"community-banner"}/>
            }

            <div ref={find_more_ref}></div>

          <Nav className="tab-switch secondary-nav">
          <Container>
            <Row>
              <ul className="d-flex col-lg-12">
                {(details?.Statistics_api_url && details?.statistics_title) &&
                <li class="active"><a href="#statistics-section">Statistics</a></li>
                }
                {details.video_playlists_id &&
                <li><a href="#videoSections">Videos</a></li>
                }
                <li><a href="#properties-section">Properties</a></li>
                <li><a href="#contact-info">Additional Information</a></li>
                {details.Show_Insights && details.Show_Insights.Show_Insights &&  details.Show_Insights.Filter_by_Tag &&
                <li><a href="#latest-blogs">Blogs and articles</a></li>
                }
                {details.community_districts && details.community_districts.length > 0 &&
                   <li><a href="#sub-community-section">Community Districts</a></li>
                }
                {details.Show_Google_Reviews && details.Show_Google_Reviews.Show_Google_Review &&
                   <li><a href="#review-blogs">Review</a></li>
                }
              </ul>
            </Row>
          </Container>
        </Nav>

            {/* ======= Intro Section ====== */}
            {/*details.Intro_Content && details.Intro_Content.length > 0 &&
                <ContactInfo Name={details.Name} Title={details.Intro_Content[0].Intro_Title} Content={details.Intro_Content[0].Intro_Text} Office_Details={details.Intro_Content[0].Choose_Office && details.Intro_Content[0].Choose_Office} AccordionItems={details.Accordion_Content && details.Accordion_Content.Add_Accor_Item.length > 0 && details.Accordion_Content.Add_Accor_Item} Accordian_Title={details.Intro_Content[0].Intro_Accordian_Title} Accordian_List={details.Intro_Content[0].Intro_Accordian_List} Services_Title={details.Intro_Content[0].Intro_Services_Title} Services_List={details.Intro_Content[0].Intro_Services_List} Service_Text={details.Intro_Content[0].Service_Text} Service_Icon={details.Intro_Content[0].Service_Icon} Collection="Community" classNames="grey_bg" Tile_Blocks={details.Community_Districts}/>
            */}

          {details?.statistics_title &&
            <div className="statistics-section" id="statistics-section">
              <Container>
                <Row>
                  <div className="col-xl-8">
                    <div className="statistics">
                      {details?.statistics_title &&
                      <h2>{details?.statistics_title}</h2>
                      }
                      {details?.statistics_content &&
                      <p>{details?.statistics_content}</p>
                      }
                       <Col className="col-statics">
                  <Card>
                    <Card.Body>
                      <Chart zoneName={details?.Statistics_api_url} />
                    </Card.Body>
                  </Card>
                </Col>
                      {/* {details?.Statistics_api_url &&
                        <>
                          <iframe src={details?.Statistics_api_url} width="100%" height="300" frameborder="0"></iframe>
                        </>
                      } */}
                    </div>
                  </div>
                  <div className="col-xl-4 right_side">
                            <>
                  
                                <div className="member-card-block">
                                  <MemberCard 
                                  RightSideModule={details.Right_side_module.ModuleType[0]} 
                                  openModalForm={openModalForm} 
                                  Pagename={details.Name}                                    
                                  WhatsappText={details.Name} 
                                  page={details} 
                                  location={details.Name} 
                                  template={"common"}/> 
                                  </div>

                                  <Modal
                                    id="valuation_modal"
                                    show={isOpen}
                                    onHide={() => setIsOpen(false)}
                                    backdrop="static"
                                    keyboard={false}
                                    className="member-contact-modal"
                                    >
                                  <Modal.Header closeButton className="contact-close-btn memberPopup">
                                  <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}<br />{details.Name}</h3></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                  <SpeakAdvisorForm formName={`${details.Name} - ${modalHeading}`} formType={modalType} formLabel={modalHeading} subjectName = {modalHeading + " " + details.Name}/>
                                  </Modal.Body>
                                  </Modal>
                            </>
                  </div>

                </Row>
              </Container>
            </div>
         }


            {details.video_playlists_id && 
                // <CountSection Counter_Block={details.Show_Counter_Block.Counter_Block} Counter_Title={details.Show_Counter_Block.Title} Area_Name={details.Name} />
                <div className="videoSections" id="videoSections">
                    <Videos Playlist_Videos={details.video_playlists_id} Name={details.Name}/>
                </div>
            }            

             {/* ======= Featured Properties ====== */}
             {/*details.Show_NewProject_Property && details.Show_NewProject_Property.Publish && <NewProjectRating Heading={details.Show_NewProject_Property.Title && details.Show_NewProject_Property.Title} CommunityTab={false} Area_Name={details.Name} /> */}

            {<CommunitySearchList Name={details.Name} />}

            {details.__typename === "GLSTRAPI_CommunityDistrict" && details.Name && 
                // <CountSection Counter_Block={details.Show_Counter_Block.Counter_Block} Counter_Title={details.Show_Counter_Block.Title} Area_Name={details.Name} />
                <CountSection  district= {district_data[0].community.Name} Area_Name={details.Name.replace('Commercial','').replace('Residential','')} get_url={get_url}/>
            }

                    
            {/* ======= Tiles  Section ====== */}
            {details.community_districts && details.community_districts.length > 0 &&
                <>
                <div className="sub-community-section" id="sub-community-section">
                <TilesBlock Tile_Blocks={details.community_districts} Collection="Community" Name={details.Name} />
                </div>
                </>
            }

            {details.Intro_Content && details.Intro_Content.length > 0 &&
                <ContactInfo Name={details.Name} Title={details.Intro_Content[0].Intro_Title} Content={details.Intro_Content[0].Intro_Text} Office_Details={details.Intro_Content[0].Choose_Office && details.Intro_Content[0].Choose_Office} AccordionItems={details.Accordion_Content && details.Accordion_Content.Add_Accor_Item.length > 0 && details.Accordion_Content.Add_Accor_Item} Accordian_Title={details.Intro_Content[0].Intro_Accordian_Title} Accordian_List={details.Intro_Content[0].Intro_Accordian_List} Services_Title={details.Intro_Content[0].Intro_Services_Title} Services_List={details.Intro_Content[0].Intro_Services_List} Service_Text={details.Intro_Content[0].Service_Text} Service_Icon={details.Intro_Content[0].Service_Icon} Collection="Community" classNames="grey_bg-" Tile_Blocks={details.Community_Districts}/>
            }


            {/* ======= Map Section ====== */}
            {
                details.Latitude && details.Longitude &&
                <GoogleSingleMap lat={parseFloat(details.Latitude)} lng={parseFloat(details.Longitude)} height={"650px"}/>
            }


            {/* ======= Featured Properties ====== */}
            {/*details.Show_Property && details.Show_Property.Show_Property && <ProjectRating Heading={details.Show_Property.Title && details.Show_Property.Title} CommunityTab={false} Area_Name={details.Name} /> */}

            {/* ======= Google Reviews ====== */}
            {details.Show_Google_Reviews && details.Show_Google_Reviews.Show_Google_Review && <div className="d-md-flex flex-wrap" id="review-blogs"> <Reviews testimonials={testimonials} /> </div> }

            {/*======= Latest Blogs Insights-Case Studies ====== */}
                       
            {(details?.Blogs_list && details?.__typename!="GLSTRAPI_CommunityDistrict") &&
              <div className="d-md-flex flex-wrap" id="latest-blogs">
                 <LatestBlogsbyCategory  Filter_by_Tag={details?.Blogs_list} /> 
               </div> 
            }
             {/*(details?.Blogs_list && details?.__typename=="GLSTRAPI_CommunityDistrict") &&
              <div className="d-md-flex flex-wrap">
                 <LatestBlogsbyCategory  Filter_by_Tag={details?.Blogs_list} /> 
               </div> 
            */}  

             {/*(details.Name=="Viva Bahriya" || details.Name=="Qetaifan islands south") &&
              <div className="">
                <LatestBlogsbyCategory Filter_by_Tag={categories} /> 
              </div>
            */}

            <div className="mt-5 mb-5 community-bottom"></div>
        </> : <div className="main-banner no_lines">
                <div className="loader_div">
                    {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
                </div>
            </div>
        }
    </Layout>
    )
}

export const data = graphql`
query CommunityDetails($slug: String) {
  glstrapi {
    communities(filters: {URL: {eq: $slug}}) {
      data {
        id
        __typename
        attributes {
          Name
          URL
          Location
          Latitude
          Longitude
          rent_button_label
          rent_button_link
          sale_button_label
          sale_button_link
          Content
          Meta_Title
          Meta_Description
          video_playlists_id
          statistics_title
          statistics_content
          Statistics_api_url  
          Banner_Image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }

          Blogs_list {
            Button_name
            Contents
            Filter_by_Tag
            Title
            Hading
            button_link
            id
            blogs_title {
              data {
                attributes {
                  Title
                }
              }
            }
          }

         Right_side_module {
            data {
              id
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModRightSideBlock {
                    id
                    CTA_1_Label
                    CTA_1_URL
                    CTA_2_Label
                    CTA_2_URL
                    Content
                    Title
                  }
                }
              }
            }
          }

          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content {
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
              data {
                attributes {
                  Name
                  Direct_Phone
                  Image {
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
          }
          Show_Property {
            Title
            Show_Property
            Stb_List {
              Stb_List_Title
            }
          }
          Show_NewProject_Property {
            Title
            Publish
          }
          Show_Google_Reviews {
            Show_Google_Review
          }
          Show_Insights {
            Filter_by_Tag
            Show_Insights
            content
          }
          community_districts(pagination: {limit: 100}) {
            data {
              id
              attributes {
                Name
                URL
                Tile_Image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                imagetransforms
                ggfx_results {
                  data {
                    attributes {
                      src_cftle
                      transforms
                    }
                  }
                }
              }
            }
          }
          plr_srch_cmties {
            data {
              attributes{
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
            
          }
        }
      }
    }

    communityDistricts(filters: {URL: {eq: $slug}}) {
      data {
        id
        attributes {
          __typename
          Name
          URL
          Location
          Latitude
          Longitude
          Content
          Meta_Title
          Meta_Description
          video_playlists_id
          community {
            data {
              attributes {
                Name
              }
            }
          }
          Banner_Image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Banner_Video {
            data {
              attributes {
                url
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content {
            Intro_Title
            Intro_Content
            Intro_Text
            Choose_Office {
              data {
                attributes {
                  Name
                  Direct_Phone
                }
              }
            }
            Intro_Services_Title
            Intro_Services_List {
              Service_Text
              Service_Icon {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
            Intro_Accordian_Title
            Intro_Accordian_List {
              Content
              Title
            }
          }
          Accordion_Content {
            Add_Accor_Item {
              Title
              Content
            }
          }
          Show_Property {
            Title
            Show_Property
          }
          Show_Google_Reviews {
            Show_Google_Review
          }
          Show_Counter_Block {
            Show_Counter_Block
            Counter_Block {
              CTA_Label
              CTA_Link
              Name
              id
              Count
            }
          }
          plr_srch_cmties {
            data {
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
          }
        }
      }
    }


    communityDistricts(filters:{URL:{eq: $slug}}){
      data {
        id
        attributes {
          Name
          URL
          Location
          Latitude
          Longitude
          Content
          Meta_Title
          Meta_Description
          community {
            data {
              attributes {
                Name
              }
            }
          }
          Banner_Image{
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Banner_Video {
            data {
              attributes {
                url
              }
            }
          }
          imagetransforms
          ggfx_results {
            data {
              attributes {
                src_cftle
                transforms 
              }
            }
          }
          Intro_Content{
              Intro_Title
              Intro_Content
              Intro_Text
              Choose_Office {
                data {
                  attributes {
                    Name
                    Direct_Phone
                  }
                }
                  
              }
              Intro_Services_Title
              Intro_Services_List {
                Service_Text
                Service_Icon {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
              Intro_Accordian_Title
              Intro_Accordian_List {
                Content
                Title
              }
          }
          Accordion_Content{
              Add_Accor_Item{
                Title
                Content
              }
          }
          Show_Property{
              Title
              Show_Property
          }
          Show_Google_Reviews{
              Show_Google_Review
          }
          Show_Counter_Block {
              Show_Counter_Block
              Counter_Block {
                CTA_Label
                CTA_Link
                Name
                id
                Count
              }
          }         

          plr_srch_cmties {
            data {
              attributes {
                Module_Name
                ModuleType {
                  ... on GLSTRAPI_ComponentModContentBlock {
                    Title
                    Content
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default Community_Details
